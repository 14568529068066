import { BlockContainer } from '@/components/Blocks/BlockContainer';
import Map from '@/components/Map';
import { createBlock } from '@/lib/parsers/blocks';
import React from 'react';

export type InteractiveMapBlockProps = React.PropsWithChildren;

const InteractiveMapBlock = createBlock<'blocks_interactiveMap_BlockType'>(
  ({ heading, _blockMeta: meta }) => {
    return (
      <BlockContainer
        meta={meta}
        ContainerProps={
          {
            /* maxWidth: 'none' */
          }
        }
        disableGrid>
        <Map heading={heading ?? undefined} />
      </BlockContainer>
    );
  }
);

export default InteractiveMapBlock;
