import DownloadButton from '@/components/DownloadButton';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';

const MediaDownloadBlock = createBlock<'blocks_mediaDownload_BlockType'>(
  ({ documentMultiple = [], _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          disableTransition: true,
          meta,
          GridColProps: {
            md: 5,
            offsetMd: 2,
            cx: { display: 'flex', direction: 'column', rowGap: 'gutter' },
          },
        }}>
        {documentMultiple.map((document) => (
          <DownloadButton key={document?.id} document={document} />
        ))}
      </BlockContainer>
    );
  }
);

export default MediaDownloadBlock;
