import { createBlock } from '@/lib/parsers/blocks';
import { Entry, EntrySectionId, PageEntryTypes } from '@/lib/parsers/entries';
import { CardProps } from '../../../Card';

/** Hook to apply card "overrides" and extra features */
export const useEntryCard = createBlock<
  'blocks_entryCard_BlockType',
  NoProps,
  PageEntryTypes | null
>((props) => {
  const {
    entrySingle: item,
    heading: maybeHeading,
    content: maybeContent,
    imageSingle: maybeImage,
  } = props;

  // Bail early
  if (!item) return null;

  if (maybeHeading) item.title = maybeHeading;
  if (maybeContent) item.summary = maybeContent;
  if (maybeImage) item.entryImage = maybeImage;

  return item as PageEntryTypes;
});

export type EntryCardProps<T extends EntrySectionId = EntrySectionId> = CardProps<Entry<T>>;
