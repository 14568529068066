export type MapMenuItem = {
  label: string;
  id: string;
};

type MapMenuGroup = {
  label: string;
  items: MapMenuItem[];
};

type MapMenuItems = MapMenuGroup[];

export const mapMenuItems: MapMenuItems = [
  {
    label: 'Arts related organisations',
    items: [
      {
        id: '01',
        label: 'ABC Southbank Centre',
      },
      {
        id: '02',
        label: 'ACMI',
      },
      {
        id: '03',
        label: 'Artplay',
      },
      {
        id: '04',
        label: 'Arts Centre Melbourne Hamer Hall',
      },
      {
        id: '05',
        label: 'Arts Centre Melbourne Sidney Myer Music Bowl',
      },
      {
        id: '06',
        label: 'Arts Centre Melbourne Theatres Building',
      },
      {
        id: '07',
        label: 'Australian Centre for Contemporary Art',
      },
      {
        id: '08',
        label: 'Buxton Contemporary, UniMelb',
      },
      {
        id: '09',
        label: 'Chunky Move',
      },
      {
        id: '10',
        label: 'Federation Square – Arts Precinct',
      },
      {
        id: '11',
        label: 'Future The Fox: NGV Contemporary',
      },
      {
        id: '12',
        label: 'Government House',
      },
      {
        id: '13',
        label: 'Iwaki Auditorium',
      },
      {
        id: '14',
        label: 'Koorie Heritage Trust (KHT)',
      },
      {
        id: '15',
        label: 'Malthouse Theatre',
      },
      {
        id: '16',
        label: 'Melbourne Recital Centre',
      },
      {
        id: '17',
        label: 'Melbourne Theatre Company HQ',
      },
      {
        id: '18',
        label: 'Melbourne Theatre Company Southbank Theatre',
      },
      {
        id: '19',
        label: 'MPavilion',
      },
      {
        id: '20',
        label: 'NGV International',
      },
      {
        id: '21',
        label: 'Opera Australia',
      },
      {
        id: '22',
        label: 'Royal Botanic Gardens Melbourne',
      },
      {
        id: '23',
        label: 'Shrine of Remembrance',
      },
      {
        id: '24',
        label: 'Signal',
      },
      {
        id: '25',
        label: 'The Primrose Potter Australian Ballet Centre',
      },
      {
        id: '26',
        label: 'The Boyd School Studios',
      },
      {
        id: '27',
        label: 'The Edge',
      },
      {
        id: '28',
        label: 'The Ian Potter Centre: NGV Australia',
      },
      {
        id: '29',
        label: 'Faculty of Fine Arts and Music, UniMelb',
      },
      {
        id: '30',
        label: 'Victorian College of the Arts Secondary School',
      },
    ],
  },
  {
    label: 'Landmarks',
    items: [
      {
        id: '31',
        label: 'Sports & Entertainment Precinct',
      },
      {
        id: '32',
        label: 'Flinders Street Station',
      },
      {
        id: '33',
        label: 'Melbourne Cricket Ground',
      },
      {
        id: '34',
        label: 'Southgate',
      },
      {
        id: '35',
        label: 'Victoria Barracks',
      },
    ],
  },
];
