'use client';

import { useTranslations } from 'next-intl';
import Btn from '../ui/Btn';
import Txt from '../ui/Txt';
import { ENTRY_TYPE_URI_SETTINGS } from '@/lib/constants';
import Grid, { GridColProps } from '../ui/Grid';
import Box from '../ui/Box';

export default function LatestArticlesHeading(props: GridColProps) {
  const t = useTranslations('common');
  return (
    <Grid.Col cx={{ display: 'flex', justifyContent: 'between' }} {...props}>
      <Txt as="h2" variant="h2">
        {t('recentNews')}
      </Txt>
      <Box>
        <Btn color="accent" variant="filled" href={'/' + ENTRY_TYPE_URI_SETTINGS.articleIndex}>
          {t('viewMore')}
        </Btn>
      </Box>
    </Grid.Col>
  );
}
