import { BlockContainer } from '@/components/Blocks';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import Btn from '../ui/Btn';

const RichText = createBlock<'blocks_richText_BlockType'>(
  ({ content, linkField, backgroundColorOpacity, _blockMeta }) => {
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{
          GridColProps: { offsetMd: 2, md: 8 },
          meta: _blockMeta,
          disableTransition: true,
          colorSet: backgroundColorOpacity,
          paper,
        }}>
        <Txt html>{content}</Txt>
        {linkField && <Btn variant="filled" color="accent" cx={{ mT: 'xl' }} {...linkField} />}
      </BlockContainer>
    );
  }
);

export default RichText;
