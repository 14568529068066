'use client';

import { useMapItem } from '@/components/Map/useMap';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import React from 'react';
import * as style from './Map.css';
import { MapMenuItem as MapMenuItemType } from './mapMenuItems';
const handleScrollIntoView = (item: HTMLElement, container: HTMLElement) => {
  // First get this element's y position within the container
  const itemTop = item.offsetTop;
  const itemHeight = item.clientHeight;
  const containerHeight = container.clientHeight;
  const containerScrollTop = container.scrollTop;
  const containerScrollBottom = containerScrollTop + containerHeight;
  const itemBottom = itemTop + itemHeight;

  const isInView = itemTop >= containerScrollTop && itemBottom <= containerScrollBottom;
  console.log('isInView', isInView);
  if (isInView) return; // Don't scroll if already in view

  const scrollTo = itemTop - containerHeight / 2 + itemHeight / 2;
  console.log('scrollTo', scrollTo);
  container.scrollTo({
    top: scrollTo,
    behavior: 'smooth',
  });
};

type MapMenuItemProps = {
  item: MapMenuItemType;
  containerRef: React.RefObject<HTMLElement>;
};

export const MapMenuItem = ({ item, containerRef }: MapMenuItemProps) => {
  const itemRef = React.useRef<HTMLLIElement>(null);

  const { isActive, setActive } = useMapItem(item.id);
  const [isHovered, setIsHovered] = React.useState(false);

  React.useEffect(() => {
    if (isActive && !isHovered) {
      const item = itemRef.current;
      const container = containerRef.current;
      if (!item || !container) return;

      handleScrollIntoView(item, container);
    }
  }, [containerRef, isActive, isHovered]);

  const handleHover = (hovered: boolean) => {
    setIsHovered(hovered);
    setActive(hovered);
  };

  return (
    <Flex
      key={item.id}
      ref={itemRef}
      as="li"
      direction="row"
      className={style.listItem({ isActive })}
      // onClick={() => setActive(!isActive)}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}>
      <Txt variant="small" medium as="span" className={style.listItemId}>
        {item.id}
      </Txt>
      <Txt variant="small" as="span" className={style.listItemLabel}>
        {item.label}
      </Txt>
    </Flex>
  );
};
