/* eslint-disable @typescript-eslint/no-var-requires */
import { Block, filterBlocks } from '@/lib/parsers/blocks';
import { assertUnreachable } from '@liquorice/allsorts-craftcms-nextjs';

import InteractiveMapBlock from '@/components/Blocks/BlockComponents/InteractiveMapBlock';
import { useViewBlocks } from '@/lib/store/hooks';
import RichText from '../RichText';
import AnnouncementBlock from './BlockComponents/AnnouncementBlock';
import CollaboratorCardsBlock from './BlockComponents/CollaboratorCardsBlock';
import EntryCardBlock from './BlockComponents/EntryCardBlock';
import EntryCardsBlock from './BlockComponents/EntryCardsBlock';
import ExternalCardBlock from './BlockComponents/ExternalCardBlock';
import FormBlock from './BlockComponents/FormBlock';
import ImageBlock from './BlockComponents/ImageBlock';
import ImageCarouselBlock from './BlockComponents/ImageCarouselBlock';
import ImageGalleryBlock from './BlockComponents/ImageGalleryBlock';
import LatestArticlesBlock from './BlockComponents/LatestArticlesBlock';
import MapBlock from './BlockComponents/MapBlock';
import MediaDownloadBlock from './BlockComponents/MediaDownloadBlock';
import MediaGalleryBlock from './BlockComponents/MediaGalleryBlock';
import ProfileCardsBlock from './BlockComponents/ProfileCardsBlock';
import QuoteBlock from './BlockComponents/QuoteBlock';
import SocialMediaBlock from './BlockComponents/SocialMediaBlock';
import SubscribeFormBlock from './BlockComponents/SubscribeFormBlock';
import TwoColumnTextBlock from './BlockComponents/TwoColumnTextBlock';
import VideoBlock from './BlockComponents/VideoBlock';
import { BlockProvider, BlocksContext, BlocksContextProvider } from './useBlock';

export const BlockInner = (block: Block) => {
  switch (block.__typename) {
    case 'blocks_richText_BlockType':
      return <RichText {...block} />;
    case 'blocks_twoColumnText_BlockType':
      return <TwoColumnTextBlock {...block} />;
    case 'blocks_announcement_BlockType':
      return <AnnouncementBlock {...block} />;
    case 'blocks_quote_BlockType':
      return <QuoteBlock {...block} />;
    case 'blocks_socialMedia_BlockType':
      return <SocialMediaBlock {...block} />;
    case 'blocks_entryCard_BlockType':
      return <EntryCardBlock {...block} />;
    case 'blocks_externalCard_BlockType':
      return <ExternalCardBlock {...block} />;
    case 'blocks_latestArticles_BlockType':
      return <LatestArticlesBlock {...block} />;
    case 'blocks_entryCards_BlockType':
      return <EntryCardsBlock {...block} />;
    case 'blocks_collaboratorCards_BlockType':
      return <CollaboratorCardsBlock {...block} />;
    case 'blocks_profileCards_BlockType':
      return <ProfileCardsBlock {...block} />;
    case 'blocks_image_BlockType':
      return <ImageBlock {...block} />;
    case 'blocks_video_BlockType':
      return <VideoBlock {...block} />;
    case 'blocks_imageCarousel_BlockType':
      return <ImageCarouselBlock {...block} />;
    case 'blocks_imageGallery_BlockType':
      return <ImageGalleryBlock {...block} />;
    case 'blocks_interactiveMap_BlockType':
      return <InteractiveMapBlock {...block} />;
    case 'blocks_mediaGallery_BlockType':
      return <MediaGalleryBlock {...block} />;
    case 'blocks_mediaDownload_BlockType':
      return <MediaDownloadBlock {...block} />;
    case 'blocks_map_BlockType':
      return <MapBlock {...block} />;
    case 'blocks_subscribeForm_BlockType':
      return <SubscribeFormBlock {...block} />;
    case 'blocks_form_BlockType':
      return <FormBlock {...block} />;
    default:
      return assertUnreachable(block);
  }
};

type BlocksProps = {
  blocks?: Block[];
} & BlocksContext;

const Blocks = ({ blocks: maybeBlocks, ...context }: BlocksProps) => {
  const viewBlocks = useViewBlocks();

  const blocks = maybeBlocks ? filterBlocks(maybeBlocks).blocks : viewBlocks.blocks;

  const blocksOutput = blocks.map((v, i) => (
    <BlockProvider key={i + 1} value={v}>
      <BlockInner {...v.block} />
    </BlockProvider>
  ));

  return blocks.length ? (
    <BlocksContextProvider value={context}>{blocksOutput}</BlocksContextProvider>
  ) : (
    <></>
  );
};

export const BlockChildren = Blocks;

export default Blocks;
