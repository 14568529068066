'use client';

import { Entry } from '@/lib/parsers/entries';
import CardGrid from '../CardGrid';
import { ArticleCard } from '../EntryCard';
import Grid, { GridColProps } from '../ui/Grid';

type LatestArticlesGridProps = GridColProps & {
  articles?: Entry<'article'>[];
};

export default function LatestArticlesGrid({ articles }: LatestArticlesGridProps) {
  return (
    articles && (
      <Grid.Col>
        <CardGrid items={articles} Component={ArticleCard} md={3} />
      </Grid.Col>
    )
  );
}
