import { createBlock } from '@/lib/parsers/blocks';
import { Entry } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useProfileCardsBlock = createBlock<
  'blocks_profileCards_BlockType',
  NoProps,
  Entry<'profile'>[] | null
>((props) => {
  const { profileMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'profile'>[];
});
