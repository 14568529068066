import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import ImageWithCaption from '@/components/ImageWithCaption';
import Grid from '@/components/ui/Grid';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';

const ImageBlock = createBlock<'blocks_image_BlockType'>(
  ({ imageSingle, caption, _blockMeta: meta, backgroundColorOpacity }) => {
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{ disableTransition: true, meta, colorSet: backgroundColorOpacity, paper }}>
        <Grid>
          <Grid.Col>
            <ImageWithCaption
              imageProps={{
                ...imageSingle,
                rounded: false,
                ratio: 'landscape',
              }}
              caption={caption}
            />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageBlock;
