import Box, { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { BlockMeta } from '@/lib/parsers/blocks';
import { isEntry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';
import { motion, MotionProps, useInView } from 'framer-motion';
import React from 'react';
import { useDevMode } from '../_DevToolbar';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import * as style from './BlockContainer.css';
import { useBlock, useBlocksContext } from './useBlock';

export type BlockContainerProps = BoxProps<
  typeof motion.section,
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    ContainerProps?: ContainerProps;
    onInView?: (inView: boolean) => void;
    disableTransition?: boolean;
    disableGrid?: boolean;
    meta?: BlockMeta;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableGrid,
    disableTransition,
    onInView,
    style: customStyleProp,
    GridColProps,
    GridProps,
    ContainerProps,
    colorSet,
    paper,
    meta,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const blocksContext = useBlocksContext();
  const ref = useForwardedRef(forwardedRef);
  const isDev = useDevMode((v) => v.value);
  const { anchor, block } = useBlock();
  const isInView = useInView(ref, {
    once: true,
    margin: '0px 0px -100px 0px',
  });

  const isLast = !!block._blockMeta?.last;
  const isFirst = !!block._blockMeta?.first;

  const maybeBlockColorOpacity = maybeGet(block, 'backgroundColorOpacity') || undefined;
  const maybeBlockColorSet = paper && colorSet ? colorSet : undefined;

  const maybeBlockBg = maybeBlockColorOpacity || maybeBlockColorSet;

  const maybePrevBlockBg = maybeGet(block._blockMeta?.previousBlock, 'backgroundColorOpacity');

  const sameBg = maybePrevBlockBg === maybeBlockBg;

  const page = useView();

  const isReducedWidth = isEntry(page, 'article') || isEntry(page, 'page', 'default');

  const customGridColProps: GridColProps = {
    ...(isReducedWidth && {
      offsetMd: 2,
      md: 8,
    }),
    ...GridColProps,
  };

  React.useEffect(() => {
    onInView && onInView(isInView);
  }, [isInView, onInView]);

  const styleProp: MotionProps['style'] = {
    ...(!disableTransition && {
      transition: 'opacity 0.4s ease-in',
      opacity: isInView ? 1 : 0,
    }),
    ...customStyleProp,
  };

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
    })
  );

  const inner = children;

  return (
    <>
      {isDev && <code>{block?.__typename.split('_')[1]}</code>}
      <Box
        as={motion.section}
        id={anchor}
        data-block={meta?.typename.split('_')[1]}
        ref={ref}
        style={styleProp}
        className={className}
        {...{ colorSet, paper, ...props }}>
        <Container
          cx={{
            marginTop: maybeBlockBg || isFirst ? 'none' : '6xl',
            // marginBottom: maybeBlockBg || isLast ? 'none' : '6xl',  // include last block conditional
            marginBottom: maybeBlockBg ? 'none' : '6xl',
            paddingBottom: maybeBlockBg ? '6xl' : 'none',
            paddingTop: (maybeBlockBg && !sameBg) || isFirst ? '6xl' : 'none',
          }}
          {...ContainerProps}>
          {disableGrid ? (
            inner
          ) : (
            <Grid {...GridProps}>
              <Grid.Col {...customGridColProps}>{children}</Grid.Col>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
});
