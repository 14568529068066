import MapMenu from '@/components/Map/MapMenu';
import MapCoMapSvg from '@/components/Map/svg/MapcoMap.svg';
import MapCoMapMarkersSvg from '@/components/Map/svg/MapcoMapMarkers.svg';
import useMap from '@/components/Map/useMap';
import Box from '@/components/ui/Box';
import Container from '@/components/ui/Container';
import Txt from '@/components/ui/Txt';
import * as style from './Map.css';

export type MapProps = {
  heading?: string;
};

const Map = ({ heading }: MapProps) => {
  const hasActiveMarker = useMap((state) => !!state.currentMarker);

  return (
    <Container disablePadding className={style.root({ hasActiveMarker })}>
      {heading && (
        <Txt variant="h3" medium>
          {heading}
        </Txt>
      )}
      <Box className={style.inner}>
        <Box className={style.mapCol}>
          <Box className={style.mapContainer}>
            <MapCoMapSvg className={style.mapSvg} />
            <MapCoMapMarkersSvg className={style.mapMarkersSvg} />
          </Box>
        </Box>
        <Box className={style.menuCol}>
          <MapMenu />
        </Box>
      </Box>
    </Container>
  );
};

export default Map;
