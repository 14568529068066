import CardGrid from '@/components/CardGrid';
import { ArticleCard } from '@/components/EntryCard';
import { FeaturedCard } from '@/components/EntryCard/EntryCard.Featured';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { useLatestEntries } from '@/lib/getters/useLatestEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { useBreakpointMax } from '@/theme';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../..';
import { useFeaturedArticle, useLatestAricles } from './useLatestArticlesBlock';

const LatestArticlesBlock = createBlock<'blocks_latestArticles_BlockType'>(
  ({ heading, _blockMeta: meta, ...props }) => {
    const { result } =
      useLatestEntries(
        { section: 'article', limit: 5, orderBy: 'postDate desc' },
        'article_default_Entry'
      ) ?? {};

    const curatedItems = useLatestAricles(props);
    const curatedFeature = useFeaturedArticle(props);
    const t = useTranslations('common');

    const md = useBreakpointMax('md');

    const isCurated = !!curatedItems || !!curatedFeature;

    const featured = curatedFeature ?? !isCurated ? (result ?? [])[0] : undefined;
    const items = curatedItems ?? !isCurated ? (result ?? []).slice(1) : undefined;

    return (
      <BlockContainer {...{ disableTransition: true, meta }}>
        <Grid cx={{ rowGap: '3xl' }}>
          {heading && (
            <Grid.Col cx={{ display: 'flex', justifyContent: 'between' }}>
              <Txt as="h2" variant="h2" cx={{ mB: 'none' }}>
                {heading}
              </Txt>
              {!md && (
                <Box>
                  <Btn color="accent" variant="filled" href="/news">
                    {t('viewMore')}
                  </Btn>
                </Box>
              )}
            </Grid.Col>
          )}
          {!md ? (
            <>
              {featured && (
                <Grid.Col>
                  <FeaturedCard item={featured} />
                </Grid.Col>
              )}
              {items && (
                <Grid.Col>
                  <CardGrid items={items} Component={ArticleCard} md={6} lg={3} />
                </Grid.Col>
              )}
            </>
          ) : (
            result &&
            result.map((item) => {
              return (
                <Grid.Col key={item.id} sm={6}>
                  <ArticleCard item={item} />
                </Grid.Col>
              );
            })
          )}
          {md && (
            <Grid.Col>
              <Btn color="accent" variant="filled" href="/news">
                {t('viewMore')}
              </Btn>
            </Grid.Col>
          )}
        </Grid>
      </BlockContainer>
    );
  }
);

export default LatestArticlesBlock;
