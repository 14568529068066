import BlockHeader from '@/components/BlockHeader';
import CardGrid from '@/components/CardGrid';
import { InstagramPostCard } from '@/components/EntryCard/EntryCard.InstagramPost';
import SkeletonCards from '@/components/Skeleton/SkeletonCards';
import Socials from '@/components/Socials';
import { useLatestEntries } from '@/lib/getters/useLatestEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useSocialMediaBlock } from './useSocialMediaBlock';

const SocialMediaBlock = createBlock<'blocks_socialMedia_BlockType'>(
  ({ heading, _blockMeta: meta, ...props }) => {
    const items = useSocialMediaBlock(props);
    const { result, loading } =
      useLatestEntries(
        { orderBy: 'postDate DESC', limit: 4, section: 'instagramPost' },
        'instagramPost_default_Entry'
      ) ?? {};

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          colorSet: 'accent',
          paper: true,
        }}>
        <BlockHeader heading={heading} HeadingProps={{ cx: { mB: 'sm' } }} />
        <Socials
          FlexProps={{ direction: 'row', cx: { columnGap: 'lg', mB: 'xl' } }}
          BtnProps={{ variant: 'unstyled' }}
        />
        {items ? (
          <CardGrid items={items} Component={InstagramPostCard} xs={6} md={3} />
        ) : loading ? (
          <SkeletonCards
            amount={4}
            xs={6}
            md={3}
            SkeletonItems={{ items: [{ variant: 'rounded', height: 300, color: 'accent' }] }}
          />
        ) : (
          result && <CardGrid items={result} Component={InstagramPostCard} xs={6} md={3} />
        )}
      </BlockContainer>
    );
  }
);

export default SocialMediaBlock;
