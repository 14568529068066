import { fmtCategoryNames } from '@/lib/utils/format';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import Card from '../Card';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Txt from '../ui/Txt';

export const ListCard = (props: EntryCardProps) => {
  if (!props.item) return null;

  const { uri: href, title } = props.item;

  const description = maybeGet(props.item, 'summary');
  const caption = maybeGet(props.item, 'caption');
  const category = maybeGet(props.item, 'contentTypeCategorySingle');
  const contentTypeName = fmtCategoryNames(category);

  return (
    <Card
      {...props}
      fullHeight
      elevation={false}
      item={{
        title,
        description: description || caption,
        href,
      }}>
      <Card.Body cx={{ pY: 'xl', display: 'grid', rowGap: 'md' }}>
        {contentTypeName && (
          <Box>
            <Txt allCaps color="accentContrast" cx={{ p: '3xs', bgcolor: 'accent' }}>
              {contentTypeName}
            </Txt>
          </Box>
        )}
        <Card.Title variant="h3" as="span" />
        <Card.Description />
      </Card.Body>
      <Divider direction="horizontal" color="accent" size="md" />
    </Card>
  );
};

export default ListCard;
