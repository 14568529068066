'use client';

import React from 'react';
import { useEntryIndex } from './useEntryIndex';
import { useTranslations } from 'next-intl';
import * as styles from './EntryIndex.css';
import Box, { BoxProps } from '../ui/Box';
import Txt, { TxtProps } from '../ui/Txt';
import Icon from '../Icon';
import Btn, { BtnProps } from '../ui/Btn';
import useEntryIndexSubmitButton from './useEntryIndexSubmitButton';

const useEntryIndexSearchField = (): React.ComponentPropsWithRef<'input'> => {
  const ref = React.useRef<HTMLInputElement>(null);
  const searchValue = useEntryIndex((s) => s.search);
  const [value, setValue] = React.useState<string | null>(null);

  const t = useTranslations('entryIndex');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return {
    ref,
    type: 'text',
    placeholder: t('search'),
    onChange: handleChange,
    value: value ?? '',
  };
};

const useEntryIndexSearchForm = (): {
  formProps: React.ComponentPropsWithRef<'form'>;
  inputProps: React.ComponentPropsWithRef<'input'>;
  searchSummary: string | null;
} => {
  const ref = React.useRef<HTMLFormElement>(null);
  const inputProps = useEntryIndexSearchField();
  const searchSummary = useEntryIndexSearchSummary();
  const setSearchValue = useEntryIndex((s) => s.setSearch);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit');
    e.preventDefault();
    setSearchValue(inputProps.value as string);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchValue('');
  };

  return {
    formProps: {
      ref,
      onSubmit: handleSubmit,
      onReset: handleReset,
    },
    inputProps,
    searchSummary,
  };
};

const useEntryIndexSearchSummary = () => {
  const loading = useEntryIndex((s) => s.loading);
  const searchValue = useEntryIndex((s) => s.search);
  const t = useTranslations('entryIndex');

  if (!searchValue) return null;

  if (loading) return t('searchLoading', { query: searchValue });

  return t('searchSummary', { query: searchValue });
};

export type EntryIndexSearchForm = {
  FormProps?: BoxProps<'form'>;
  InputProps?: BoxProps<'input'>;
  SearchBtnProps?: BtnProps;
  SearchSummaryProps?: TxtProps;
};

const EntryIndexSearchForm = ({
  FormProps,
  InputProps,
  SearchBtnProps,
  SearchSummaryProps,
}: EntryIndexSearchForm) => {
  const { inputProps, searchSummary, formProps } = useEntryIndexSearchForm();
  const submitButtonProps = useEntryIndexSubmitButton();

  return (
    <>
      <Box className={styles.searchForm} as="form" {...formProps} {...FormProps}>
        <Box className={styles.searchInput} as="input" {...inputProps} {...InputProps} />
        <Btn
          aria-label="Submit search button"
          className={styles.searchButton}
          variant="text"
          {...(submitButtonProps as BtnProps)}
          {...SearchBtnProps}>
          <Icon name="search" />
        </Btn>
      </Box>
      <Txt as="p" variant="body" {...SearchSummaryProps}>
        {searchSummary}
      </Txt>
    </>
  );
};

export default EntryIndexSearchForm;
