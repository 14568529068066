import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import EntryCardBlock from '../EntryCardBlock';

export const EntryCardsBlock = createBlock<'blocks_entryCards_BlockType'>(
  ({ _blockMeta: meta, children = [] }) => {
    return (
      <BlockContainer {...{ meta, disableTransition: true }}>
        <SimpleGrid>
          {children.map((item, i) => (
            <EntryCardBlock key={item.id ?? i} {...item} />
          ))}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default EntryCardsBlock;
