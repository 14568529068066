import { createBlock } from '@/lib/parsers/blocks';
import { Entry } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useCollaboratorCardsBlock = createBlock<
  'blocks_collaboratorCards_BlockType',
  NoProps,
  Entry<'collaborator'>[] | null
>((props) => {
  const { collaboratorMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'collaborator'>[];
});
