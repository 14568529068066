'use client';

import { useEntryIndex } from './useEntryIndex';
import { useEntryIndexQueryHandler } from './useEntryIndexQueryHandler';
import { useTranslations } from 'next-intl';
import React from 'react';

export default function useEntryIndexSubmitButton(): React.ComponentProps<'button'> {
  const { hasChanges, triggerQuery } = useEntryIndexQueryHandler();
  // const autoSubmit = useEntryIndex((s) => s.autoSubmit);
  const loading = useEntryIndex((s) => s.loading);
  const t = useTranslations('entryIndex');

  const handleSubmit = () => {
    triggerQuery();
  };

  return {
    disabled: loading || !hasChanges,
    // hidden: !!autoSubmit,
    onClick: handleSubmit,
    type: 'button',
    children: t('submit'),
  };
}
