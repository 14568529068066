'use client';

import { themeLight } from '@/theme/mui/muiTheme';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

export const LightTheme = (props: PropsWithChildren) => {
  return <MuiThemeProvider theme={themeLight} {...props} />;
};
