'use client';

import { useMapItem } from '@/components/Map/useMap';
import { rawColorValues } from '@/theme/partials/palette.css';
import { motion } from 'framer-motion';
import React from 'react';
import * as style from '../Map.css';

const markers: MarkerProps[] = [
  { id: '01', center: [233.52, 295.41], labelOffset: [-55, 0] },
  { id: '02', center: [298.07, 58.41], labelOffset: [0, -36] },
  { id: '03', center: [375.5, 86.19], labelOffset: [57, 0] },
  { id: '04', center: [268.54, 155.52], labelOffset: [35, 0] },
  { id: '05', center: [470.63, 273.31], labelOffset: [0, 33] },
  { id: '06', center: [286, 198.4], labelOffset: [37, 0] },
  { id: '07', center: [246.25, 399.12], labelOffset: [-48, 0] },
  { id: '08', center: [297.83, 300.16], labelOffset: [0, -12] },
  { id: '09', center: [249.07, 402.55], labelOffset: [31, 0] },
  { id: '10', center: [277.39, 76.77], labelOffset: [0, -41] },
  { id: '11', center: [246.5, 258.87], labelOffset: [-21, 0] },
  { id: '12', center: [549.9, 419.44], labelOffset: [-28, 0] },
  { id: '13', center: [219.06, 288.31], labelOffset: [-65, 0] },
  { id: '14', center: [306.15, 85.86], labelOffset: [0, 19] },
  { id: '15', center: [247.4, 421.22], labelOffset: [0, 24] },
  { id: '16', center: [261.72, 302.73], labelOffset: [-58, 0] },
  { id: '17', center: [186.28, 491.96], labelOffset: [41, 0] },
  { id: '18', center: [276.75, 310.71], labelOffset: [-49, 0] },
  { id: '19', center: [338.06, 208.82], labelOffset: [30, 0] },
  { id: '20', center: [298.67, 251.32], labelOffset: [0, 0] },
  { id: '21', center: [230.43, 220.39], labelOffset: [-47, 0] },
  { id: '22', center: [618.11, 499.34], labelOffset: [0, 0] },
  { id: '23', center: [440.59, 543.34], labelOffset: [0, 47] },
  { id: '24', center: [125.93, 141.34], labelOffset: [-78, 0] },
  { id: '25', center: [250.29, 233.49], labelOffset: [-47, 0] },
  { id: '26', center: [66.94, 370.78], labelOffset: [-47, 0] },
  { id: '27', center: [318.39, 85.69], labelOffset: [0, 32] },
  { id: '28', center: [322.89, 48.06], labelOffset: [43, 0] },
  { id: '29', center: [260.56, 347.89], labelOffset: [0, 0] },
  { id: '29', center: [318.89, 323.97], labelOffset: [0, 0] },
  { id: '30', center: [184.93, 470.07], labelOffset: [-35, 0] },
  { id: '31', center: [653.43, 230.32], labelOffset: [0, 0] },
  { id: '32', center: [245.79, 92.25], labelOffset: [0, 0] },
  { id: '33', center: [728.14, 141.06], labelOffset: [0, 0] },
  { id: '34', center: [201.49, 171.52], labelOffset: [-34, 0] },
  { id: '35', center: [336.88, 440.3], labelOffset: [0, 0] },
];

type MarkerProps = {
  id: string;
  center: [x: number, y: number];
  labelOffset: [x: number, y: number];
};

const accentColor = rawColorValues.orange;

const MarkerGroup = ({ id, center, labelOffset }: MarkerProps) => {
  const { isActive, setActive, hasActive } = useMapItem(id);
  const hasLine = labelOffset[0] !== 0 || labelOffset[1] !== 0;
  const markerSize = 10;

  const groupProps = {
    className: style.marker({ isActive }),
    onClick: () => setActive(!isActive),
    // onMouseEnter: () => setActive(true),
    onMouseLeave: () => isActive && setActive(false),
    transform: `translate(${center.join(' ')})`,
  };

  const labelProps = {
    ...(hasLine && {
      transform: `translate(${labelOffset.join(' ')})`,
    }),
  };

  return (
    <motion.g
      {...groupProps}
      initial={{
        opacity: 1,
      }}
      animate={
        hasActive && !isActive
          ? {
              opacity: 0,
            }
          : undefined
      }>
      {hasLine && (
        <motion.line
          className={style.markerLine}
          x1={0}
          y1={0}
          x2={labelOffset[0]}
          y2={labelOffset[1]}
          initial={{
            strokeWidth: 1,
          }}
          animate={isActive ? { strokeWidth: 2 } : undefined}
        />
      )}
      <motion.g
        {...labelProps}
        initial={{
          scale: 1,
          x: labelOffset[0],
          y: labelOffset[1],
        }}
        animate={isActive ? { scale: 1.5 } : undefined}>
        <motion.circle
          className={style.markerIcon}
          cx={0}
          cy={0}
          r={markerSize}
          initial={{
            fill: '#000',
          }}
          animate={isActive ? { fill: accentColor } : undefined}
        />
        <motion.text
          className={style.markerText}
          initial={{
            fill: '#FFF',
          }}
          animate={isActive ? { fill: '#000' } : undefined}>
          {id}
        </motion.text>
      </motion.g>
    </motion.g>
  );
};

const MapCoMapMarkersSvg = (props: React.SVGProps<SVGSVGElement>) => {
  // const activeMarker = useMap((state) => state.currentMarker);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 783.29 708.78" {...props}>
      {markers.map((marker, i) => (
        /* marker.id === activeMarker ? null :  */ <MarkerGroup key={i} {...marker} />
      ))}
      {/* Ensure the active marker is on top */}
      {/* {markers.map((marker, i) =>
        marker.id === activeMarker ? <MarkerGroup key={i} {...marker} /> : null
      )} */}
    </svg>
  );
};

export default MapCoMapMarkersSvg;
