import Card from '@/components/Card';
import { createBlock } from '@/lib/parsers/blocks';

const ExternalCardBlock = createBlock<'blocks_externalCard_BlockType'>(
  ({ heading, content, linkField, ...props }) => {
    return (
      <Card
        {...props}
        fullHeight
        elevation={false}
        padding="sm"
        item={{
          title: heading,
          description: content,
          // image,
          href: linkField?.href,
        }}>
        {/* <Box cx={{ pX: 'sm', pT: 'sm' }}>
      <Card.Image ratio="landscape" transform="fullFit" />
    </Box> */}
        <Card.Body>
          <Card.Title variant="h4" />
          <Card.Description cx={{ mT: '3xs' }} />
        </Card.Body>
        <Card.Foot cx={{ mB: 'sm' }}>
          <Card.Cta variant="text" size="normal" endIcon="arrowRight" />
        </Card.Foot>
      </Card>
    );
  }
);

export default ExternalCardBlock;
