import BlockHeader from '@/components/BlockHeader';
import CardGrid from '@/components/CardGrid';
import { ProfileCard } from '@/components/EntryCard/EntryCard.Profile';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useProfileCardsBlock } from './useProfileCardsBlock';

const ProfileCardsBlock = createBlock<'blocks_profileCards_BlockType'>(
  ({ heading, _blockMeta: meta, ...props }) => {
    const items = useProfileCardsBlock(props) ?? [];

    return (
      <BlockContainer {...{ meta, disableTransition: true }}>
        <BlockHeader heading={heading} HeadingProps={{ cx: { mB: '5xl' } }} maxWidth="none" />
        <CardGrid
          md={6}
          lg={4}
          items={items}
          Component={ProfileCard}
          rowGutter={false}
          cx={{ rowGap: '3xl' }}
        />
      </BlockContainer>
    );
  }
);

export default ProfileCardsBlock;
