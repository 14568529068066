import Shim from '@/components/Shim';
import { createBlock } from '@/lib/parsers/blocks';
import MapGl from 'react-map-gl';
import { BlockContainer } from '../..';

import 'mapbox-gl/dist/mapbox-gl.css';

const apiToken = process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN ?? '';

const MapBlock = createBlock<'blocks_map_BlockType'>(({ _blockMeta: meta, map }) => {
  const { lng: longitude, lat: latitude } = map ?? {};

  const showMap = longitude && latitude;

  return (
    <BlockContainer {...{ disableTransition: true, meta }}>
      {showMap && (
        <Shim bgcolor="greyLight" ratio="landscapeWide">
          <MapGl
            scrollZoom={false}
            interactive={true}
            mapboxAccessToken={apiToken}
            initialViewState={{
              longitude,
              latitude,
              zoom: 14,
            }}
            style={{ width: '100%', height: '100%' }}
            mapStyle="mapbox://styles/mapbox/streets-v9"></MapGl>
        </Shim>
      )}
    </BlockContainer>
  );
});

export default MapBlock;
