import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Txt from '@/components/ui/Txt';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';

const TwoColumnTextBlock = createBlock<'blocks_twoColumnText_BlockType'>(
  ({
    smallContent,
    largeContent,
    linkField,
    flipAlignment,
    backgroundColorOpacity,
    _blockMeta: meta,
  }) => {
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{ disableTransition: true, meta, colorSet: backgroundColorOpacity, paper }}>
        <Grid
          rowGutter
          cx={{
            flexDirection: {
              xs: flipAlignment ? 'row-reverse' : 'row',
            },
          }}>
          {largeContent && (
            <Grid.Col md={8}>
              <Txt variant="h4" html>
                {largeContent}
              </Txt>
              {linkField && (
                <Btn variant="filled" color="accent" cx={{ mT: 'xl' }} {...linkField} />
              )}
            </Grid.Col>
          )}
          {smallContent && (
            <Grid.Col md={4}>
              <Txt variant="body" html>
                {smallContent}
              </Txt>
            </Grid.Col>
          )}
        </Grid>
      </BlockContainer>
    );
  }
);

export default TwoColumnTextBlock;
