'use client';

import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { useTranslations } from 'next-intl';
import React from 'react';
import * as style from './Map.css';
import { MapMenuItem } from './MapMenuItem';
import { mapMenuItems } from './mapMenuItems';

const MapMenu = () => {
  const t = useTranslations('map');
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Flex ref={containerRef} rowGap="md" className={style.mapMenuContainer}>
      <Flex className={style.legend}>
        <Flex direction="row" alignItems="center" columnGap="sm" className={style.keyItem}>
          <Box className={style.keyItemIcon({ variant: 'arts' })}>&nbsp;</Box>
          <Txt as="span" variant="small" className={style.keyItemLabel}>
            {t('artsPrecinct')}
          </Txt>
        </Flex>
        <Flex direction="row" alignItems="center" columnGap="sm" className={style.keyItem}>
          <Box className={style.keyItemIcon({ variant: 'landmarks' })}>&nbsp;</Box>
          <Txt as="span" variant="small" className={style.keyItemLabel}>
            {t('landmarks')}
          </Txt>
        </Flex>
        <Flex direction="row" alignItems="center" columnGap="sm" className={style.keyItem}>
          <Box className={style.keyItemIcon({ variant: 'trams' })}>&nbsp;</Box>
          <Txt as="span" variant="small" className={style.keyItemLabel}>
            {t('tramlines')}
          </Txt>
        </Flex>
        <Flex direction="row" alignItems="center" columnGap="sm" className={style.keyItem}>
          <Box className={style.keyItemIcon({ variant: 'noAccess' })}>&nbsp;</Box>
          <Txt as="span" variant="small" className={style.keyItemLabel}>
            {t('noPublicAccess')}
          </Txt>
        </Flex>
        <Flex direction="row" alignItems="center" columnGap="sm" className={style.keyItem}>
          <Box className={style.keyItemIcon({ variant: 'gardens' })}>&nbsp;</Box>
          <Txt as="span" variant="small" className={style.keyItemLabel}>
            {t('gardens')}
          </Txt>
        </Flex>
      </Flex>
      {mapMenuItems.map((group) => (
        <Flex key={group.label} className={style.listGroup}>
          <Txt variant="large" medium className={style.listGroupLabel}>
            {group.label}
          </Txt>
          <Flex as="ul" className={style.list}>
            {group.items.map((item) => (
              <MapMenuItem key={item.id} containerRef={containerRef} item={item} />
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default MapMenu;
