'use client';

import { Entry, isEntry } from '@/lib/parsers/entries';
import CardGrid from '../CardGrid';
import { CollaboratorCard } from '../EntryCard';
import Container, { ContainerProps } from '../ui/Container';
import * as styles from './CommunityIndex.css';
import { useView } from '@/lib/store/hooks';

type CommunityIndexProps = ContainerProps<'div'>;

export default function CommunityIndex(props: CommunityIndexProps) {
  const page = useView() as Entry<'communityIndex'>;
  const isCommunityIndex = isEntry(page, 'communityIndex');
  const items = (page.collaboratorMultiple as Entry<'collaborator'>[]) ?? [];

  if (!items || !isCommunityIndex) return null;

  return (
    <Container className={styles.root} {...props}>
      <CardGrid items={items} Component={CollaboratorCard} md={4} sm={6} />
    </Container>
  );
}
