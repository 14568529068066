import Formie from '@/components/Formie';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../..';

const FormBlock = createBlock<'blocks_form_BlockType'>(
  ({ heading, content, form, backgroundColorOpacity, _blockMeta: meta }) => {
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          colorSet: backgroundColorOpacity,
          paper,
        }}>
        <Grid>
          <Grid.Col md={4}>
            <Txt variant="h2" as="h2" cx={{ mB: 'xl' }}>
              {heading}
            </Txt>
            <Txt html cx={{ mB: 'md' }}>
              {content}
            </Txt>
          </Grid.Col>
          <Grid.Col offsetMd={1} md={6}>
            <Formie form={form ?? undefined} />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default FormBlock;
