'use client';

// import Swiper react and required modules
import { A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import 'swiper/css';
import 'swiper/css/pagination';
import { useSwiper } from 'swiper/react';
import * as style from './Carousel.css';

import { useState } from 'react';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Txt from '../ui/Txt';

const CarouselNavigation = ({
  slides,
  activeIndex,
}: {
  slides: unknown[];
  activeIndex: number;
}) => {
  return <Txt as="span">{activeIndex + 1 + '/' + slides.length}</Txt>;
};

const CarouselButtons = ({ slides, activeIndex }: { slides: unknown[]; activeIndex: number }) => {
  const swiper = useSwiper();

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };
  return (
    <>
      <Btn
        aria-label="Previous slide"
        variant="icon"
        color="accent"
        className={style.navigationPrev}
        onClick={handlePrev}
        startIcon="arrowLeft"
        {...{ disabled: activeIndex === 0 }}
      />
      <Btn
        aria-label="Next slide"
        variant="icon"
        color="accent"
        className={style.navigationNext}
        onClick={handleNext}
        startIcon="arrowRight"
        {...{ disabled: activeIndex === slides.length - 1 }}
      />
    </>
  );
};

export type CarouselProps = {
  items: JSX.Element[];
  hideNavigation?: boolean;
};

const Carousel = (props: CarouselProps) => {
  const { items = [], hideNavigation } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  if (!items.length) return <></>;
  return (
    <Swiper
      spaceBetween={32}
      modules={[A11y]}
      slidesPerView={1}
      className={style.root}
      onSlideChange={(s) => setActiveIndex(s.activeIndex)}>
      {items.map((item, i) => (
        <SwiperSlide key={i} className={style.slide}>
          <Box>{item}</Box>
        </SwiperSlide>
      ))}
      <CarouselButtons activeIndex={activeIndex} slides={items} />
      {!hideNavigation && <CarouselNavigation activeIndex={activeIndex} slides={items} />}
    </Swiper>
  );
};

export default Carousel;
