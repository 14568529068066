import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Modal as ModalMui } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import Box, { BoxProps } from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './Modal.css';

export type ModalProps = BoxProps<
  'div',
  {
    openText?: string;
    content?: string;
    BtnProps?: BtnProps;
    BtnTxtProps?: TxtProps;
  }
>;

export const Modal = ({ content, openText, BtnProps, BtnTxtProps, ...props }: ModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const t = useTranslations('actions');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!content) return null;

  return (
    <Box className={styles.root}>
      <Btn
        variant="filled"
        size="tiny"
        onClick={handleOpen}
        aria-label={openText ?? t('openModal')}
        {...BtnProps}>
        <Txt {...BtnTxtProps}>{openText ?? t('openModal')}</Txt>
      </Btn>
      <ModalMui
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-content"
        aria-describedby="modal-content"
        className={styles.modal}>
        <Box colorTheme="dark" colorSet="full" paper {...mergePropsClassName(props, styles.popup)}>
          <Txt html id="modal-content">
            {content}
          </Txt>
          <Btn
            className={styles.closeButton}
            variant="icon"
            color="neutral"
            size="small"
            endIcon="close"
            onClick={handleClose}
            aria-label={t('closeModal')}
          />
        </Box>
      </ModalMui>
    </Box>
  );
};

export default Modal;
