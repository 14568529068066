import Modal from '@/components/Modal';
import Video from '@/components/Video';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { useState } from 'react';
import { BlockContainer } from '../..';
import * as styles from './VideoBlock.css';
import { useTranslations } from 'next-intl';

const VideoBlock = createBlock<'blocks_video_BlockType'>(
  ({ videoUrl, caption, content, _blockMeta: meta, blockWidth, backgroundColorOpacity }) => {
    const t = useTranslations('common');
    const paper = toBoolean(backgroundColorOpacity) || undefined;
    const [playing, setPlaying] = useState<boolean>(false);
    const fullWidth = blockWidth === 'full';

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          colorSet: backgroundColorOpacity,
          paper,
          GridColProps: fullWidth ? { offsetMd: 'none', md: 12 } : {},
        }}>
        {caption && (
          <Txt as="p" variant="h2" cx={{ mB: '3xl' }}>
            {caption}
          </Txt>
        )}
        <Box className={styles.videoWrapper({ playing })}>
          {!playing && (
            <>
              <Btn
                aria-label="Play video"
                className={styles.button}
                onClick={() => setPlaying(true)}
                variant="icon"
                size="small"
                color="accent"
                StartIconProps={{ name: 'play', cx: { fontSize: 'h2' } }}
              />
              {content && (
                <Modal
                  openText={t('viewTranscript')}
                  content={content}
                  BtnProps={{ className: styles.modalButton }}
                  BtnTxtProps={{ allCaps: true }}
                />
              )}
            </>
          )}
          <Video playing={playing} controls={true} videoUrl={videoUrl} />
        </Box>
      </BlockContainer>
    );
  }
);

export default VideoBlock;
