import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import ImageWithCaption from '@/components/ImageWithCaption';
import Grid from '@/components/ui/Grid';

const ImageGalleryBlock = createBlock<'blocks_imageGallery_BlockType'>(
  ({ imageSingle, caption, _blockMeta: meta }) => {
    return (
      <BlockContainer {...{ disableTransition: true, meta }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <ImageWithCaption
              imageProps={{
                ...imageSingle,
                rounded: false,
                ratio: 'landscape',
              }}
              caption={caption}
            />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageGalleryBlock;
