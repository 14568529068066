import { createBlock } from '@/lib/parsers/blocks';
import { Entry } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useSocialMediaBlock = createBlock<
  'blocks_socialMedia_BlockType',
  NoProps,
  Entry<'instagramPost'>[] | null
>((props) => {
  const { instagramPostMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'instagramPost'>[];
});
