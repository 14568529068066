import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';

const AnnouncementBlock = createBlock<'blocks_announcement_BlockType'>(
  ({ _blockMeta: meta, heading, content, backgroundColorOpacity }) => {
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{
          GridColProps: { offsetMd: 2, md: 8 },
          disableTransition: true,
          meta,
          colorSet: backgroundColorOpacity,
          paper,
        }}>
        <Box colorSet="accent" paper cx={{ p: { xs: 'lg', md: 'xl' } }}>
          <Txt as="h4" variant="h4">
            {heading}
          </Txt>
          <Txt variant="body" html>
            {content}
          </Txt>
        </Box>
      </BlockContainer>
    );
  }
);

export default AnnouncementBlock;
