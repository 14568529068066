import { EntryCardDefault } from '@/components/EntryCard';
import { useEntryCard } from '@/components/Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import { createBlock } from '@/lib/parsers/blocks';

const EntryCardBlock = createBlock<'blocks_entryCard_BlockType'>((props) => {
  const item = useEntryCard(props);

  if (!item) return null;

  return <EntryCardDefault item={item} />;
});

export default EntryCardBlock;
