import { create } from 'zustand';

interface MapState {
  currentMarker: string | null;
  setCurrentMarker: (markerId: string | null) => void;
}

const useMap = create<MapState>((set) => ({
  currentMarker: null,
  setCurrentMarker: (markerId) => set({ currentMarker: markerId }),
}));

export default useMap;

export const useMapItem = (id: string) => {
  {
    const hasActive = useMap((state) => !!state.currentMarker);
    const isActive = useMap((state) => state.currentMarker === id);
    const setActive = useMap(
      (state) => (active: boolean) => state.setCurrentMarker(active ? id : null)
    );

    return { isActive, setActive, hasActive };
  }
};
