import Formie from '@/components/Formie';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { useGlobal } from '@/lib/getters/useGlobal';
import { createBlock } from '@/lib/parsers/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../..';

const SubscribeFormBlock = createBlock<'blocks_subscribeForm_BlockType'>(
  ({ heading: customHeading, content: customContent, backgroundColorOpacity, _blockMeta }) => {
    const subscribeForm = useGlobal({ handle: ['subscribeForm'] }, 'subscribeForm_GlobalSet');
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    const heading = customHeading || subscribeForm?.heading;
    const content = customContent || subscribeForm?.content;

    return (
      <BlockContainer
        {...{
          meta: _blockMeta,
          disableTransition: true,
          colorSet: backgroundColorOpacity,
          paper,
        }}>
        <Grid cx={{ rowGap: '5xl' }}>
          <Grid.Col lg={4}>
            <Txt variant="h2" as="h2" cx={{ mB: 'xl' }}>
              {heading}
            </Txt>
            <Txt html cx={{ mB: 'md' }}>
              {content}
            </Txt>
          </Grid.Col>
          <Grid.Col offsetLg={1} lg={6}>
            <Formie form={subscribeForm?.form ?? undefined}>
              {subscribeForm?.subMsg && (
                <Txt as="p" variant="body" html cx={{ mB: 'md' }}>
                  {subscribeForm?.subMsg}
                </Txt>
              )}
            </Formie>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default SubscribeFormBlock;
