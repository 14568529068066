import { createBlock } from '@/lib/parsers/blocks';
import { Entry } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useLatestAricles = createBlock<
  'blocks_latestArticles_BlockType',
  NoProps,
  Entry<'article'>[] | null
>((props) => {
  const { articleMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'article'>[];
});

/** Hook to apply card "overrides" and extra features */
export const useFeaturedArticle = createBlock<
  'blocks_latestArticles_BlockType',
  NoProps,
  Entry<'article'> | null
>((props) => {
  const { articleSingle } = props;

  // Bail early
  if (!articleSingle) return null;

  return articleSingle as Entry<'article'>;
});
