import Carousel from '@/components/Carousel';
import ImageWithCaption from '@/components/ImageWithCaption';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useView } from '@/lib/store/hooks';
import { isEntry } from '@/lib/parsers/entries';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';

const ImageCarouselBlock = createBlock<'blocks_imageCarousel_BlockType'>(
  ({ children = [], backgroundColorOpacity, _blockMeta: meta }) => {
    const page = useView();
    const isReducedWidth = isEntry(page, 'article') || isEntry(page, 'page', 'default');
    const paper = toBoolean(backgroundColorOpacity) || undefined;

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          colorSet: backgroundColorOpacity,
          paper,
          GridColProps: !isReducedWidth
            ? {
                offsetMd: 2,
                md: 8,
              }
            : {},
        }}>
        <Carousel
          hideNavigation
          items={children?.map((v, i) => (
            <ImageWithCaption
              key={i}
              {...{
                imageProps: {
                  rounded: false,
                  ratio: 'landscape',
                  ...v.imageSingle,
                },
                caption: v.caption ?? '',
                captionProps: {
                  cx: {
                    justifyContent: 'end',
                    width: '75',
                    mL: 'auto',
                    // textAlign: 'right',
                  },
                },
              }}
            />
          ))}
        />
      </BlockContainer>
    );
  }
);

export default ImageCarouselBlock;
