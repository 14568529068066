import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import CardGrid from '@/components/CardGrid';
import { useCollaboratorCardsBlock } from './useCollaboratorCardsBlock';
import { CollaboratorCard } from '@/components/EntryCard';
import BlockHeader from '@/components/BlockHeader';
import Grid from '@/components/ui/Grid';

const CollaboratorCardsBlock = createBlock<'blocks_collaboratorCards_BlockType'>(
  ({ _blockMeta: meta, heading, content, ...props }) => {
    const items = useCollaboratorCardsBlock(props) ?? [];
    return (
      <BlockContainer {...{ meta, disableTransition: true }}>
        <Grid>
          <Grid.Col md={7}>
            <BlockHeader heading={heading} content={content} maxWidth="none" />
          </Grid.Col>
        </Grid>
        <CardGrid
          xs={6}
          md={4}
          lg={3}
          items={items}
          Component={CollaboratorCard}
          rowGutter={false}
          cx={{ rowGap: { xs: 'xl', lg: '3xl' }, mT: '3xl' }}
        />
      </BlockContainer>
    );
  }
);

export default CollaboratorCardsBlock;
