import { DocumentAsset } from '@/lib/parsers/common';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import Icon from '../Icon';
import Box from '../ui/Box';
import { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './DownloadButton.css';

export type DownloadButtonProps = BtnProps & {
  title?: string;
  document?: Partial<DocumentAsset>;
};

const DownloadButton = ({ document = {}, title: customTitle, ...props }: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = document;

  const title = customTitle ?? document.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Box
      as="a"
      download={'download'}
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      className={styles.root}
      {...props}>
      <Flex direction="row" className={styles.inner}>
        <Flex
          colorSet="accent"
          paper
          alignItems="center"
          justifyContent="center"
          cx={{ p: 'sm' }}
          className={styles.icon}>
          <Icon name="download" cx={{ fontSize: 'h2' }} />
        </Flex>
        <Flex colorSet="threeQuarters" paper rowGap="xs" cx={{ p: 'xs', width: 'full' }}>
          <Txt as="span" variant="h5" medium>
            {title}
          </Txt>
          <Flex paper direction="row" justifyContent="between">
            <Txt as="span" variant="small">
              {ext}
            </Txt>
            <Txt as="span" variant="small">
              {size}
            </Txt>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DownloadButton;
