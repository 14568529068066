import React from 'react';
import * as style from './ImageWithCaption.css';
import Box, { BoxProps } from '../ui/Box';
import Image, { ImageProps } from '../Image';
import Txt, { TxtProps } from '../ui/Txt';

export type ImageWithCaptionProps = BoxProps<
  'figure',
  {
    imageProps: ImageProps;
    caption?: string | null;
    captionProps?: TxtProps<'figcaption'>;
  }
>;

const ImageWithCaption = ({
  imageProps,
  caption,
  captionProps,
  ...props
}: ImageWithCaptionProps) => {
  return (
    <Box as="figure" {...props}>
      <Image alt="" rounded {...imageProps} />
      {caption && (
        <Txt as="figcaption" className={style.caption} {...captionProps}>
          {caption}
        </Txt>
      )}
    </Box>
  );
};

export default ImageWithCaption;
