import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Txt from '@/components/ui/Txt';
import Flex from '@/components/ui/Flex';
import * as styles from './QuoteBlock.css';
import Grid from '@/components/ui/Grid';
import { useView } from '@/lib/store/hooks';
import { isEntry } from '@/lib/parsers/entries';

const QuoteBlock = createBlock<'blocks_quote_BlockType'>(
  ({ content, author, _blockMeta: meta, blockPosition }) => {
    // const leftAlign = blockPosition === 'left'; // Not needed
    const rightAlign = blockPosition === 'right';
    const page = useView();
    const isReducedWidth = isEntry(page, 'article') || isEntry(page, 'page', 'default');

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
        }}>
        <Grid>
          <Grid.Col
            offsetMd={!isReducedWidth && rightAlign ? 4 : 'none'}
            md={!isReducedWidth ? 8 : undefined}>
            <Flex rowGap="md" cx={{ pL: 'md' }} className={styles.root}>
              {content && (
                <Txt variant="h4" html>
                  {content}
                </Txt>
              )}
              {author && (
                <Flex direction="row" columnGap="4xs">
                  <Txt as="span" variant="body">
                    &mdash;
                  </Txt>
                  <Txt as="span" variant="body">
                    {author}
                  </Txt>
                </Flex>
              )}
            </Flex>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default QuoteBlock;
