import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Grid from '@/components/ui/Grid';
import ImageWithCaption from '@/components/ImageWithCaption';

const MediaGalleryBlock = createBlock<'blocks_mediaGallery_BlockType'>(
  ({ children, blockWidth, _blockMeta: meta }) => {
    const fullWidth = blockWidth === 'full';
    // const reducedWidth = blockWidth === 'reduced';

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          GridColProps: fullWidth ? { offsetMd: 'none', md: 12 } : {},
        }}>
        <Grid rowGutter>
          {children?.map((child) => {
            return (
              <Grid.Col key={child.id} md={child.imageWidth}>
                <ImageWithCaption
                  imageProps={{
                    ...child.imageSingle,
                    ratio: 'landscape',
                    rounded: false,
                  }}
                  caption={child.caption}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      </BlockContainer>
    );
  }
);

export default MediaGalleryBlock;
