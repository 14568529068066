'use client';

import CardGrid from '@/components/CardGrid';
import ListCard from '../EntryCard/EntryCard.List';
import EntryIndexNoResults from './EntryIndex.NoResults';
import { useEntryIndex } from './useEntryIndex';
import Divider from '../ui/Divider';
import SkeletonCards from '../Skeleton/SkeletonCards';

/**
 * This component is responsible for rendering the entry index results
 * or no-results message
 */
const SearchIndexResults = () => {
  const initialLoaded = useEntryIndex((s) => s.initialLoaded);
  const items = useEntryIndex((s) => s.items);
  const hasResults = items.length > 0;
  const loading = useEntryIndex((s) => s.loading);
  const showLoading = loading || !initialLoaded;

  return (
    <>
      {showLoading ? (
        <SkeletonCards
          amount={6}
          SkeletonItems={{ items: [{ variant: 'rounded', height: 200 }] }}
        />
      ) : hasResults ? (
        <>
          <Divider direction="horizontal" color="accent" size="md" />
          <CardGrid items={items} Component={ListCard} sm={12} rowGutter={false} />
        </>
      ) : (
        initialLoaded && <EntryIndexNoResults />
      )}
    </>
  );
};

export default SearchIndexResults;
